import { useState, useCallback } from "react";
import styled from "styled-components";
import { YouTubePlayer } from "./YoutubePlayer";
import {
  ShuffleIcon,
  PreviousIcon,
  PlayIcon,
  PauseIcon,
  NextIcon,
  PlayerIcon,
} from "icons";
import PlaceholderImg from "../assets/placeholder.png";
import ViewPlayListScreen from "screens/ViewPlayListScreen";
import { LoadingScreen } from "./common/LoadingScreen";
import { PlayaToast } from "utils";

export const WebplaybackYoutube = ({ isOwner, playlist, is_paused, title }) => {
  const [showPlaylist, setShowPlaylist] = useState(false);
  const playlistt = playlist;
  const [isPlaying, setIsPlaying] = useState(true);
  const [player, setPlayer] = useState(null);
  const [songDetails, setSongDetails] = useState(null);
  const [isShuffled, setIsShuffled] = useState(false);

  // Player control functions
  const handlePlay = () => {
    if (player?.playVideo) {
      player.playVideo();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (player?.pauseVideo) {
      player.pauseVideo();
      setIsPlaying(false);
    }
  };

  const handleNext = () => {
    if (player?.nextVideo) {
      player.nextVideo();
    }
  };

  const handlePrev = () => {
    if (player?.previousVideo) {
      player.previousVideo();
    }
  };

  const getNextButtonState = useCallback(() => {
    if (!player || !player.getPlaylist || !player.getPlaylistIndex)
      return false;

    const playlist = player.getPlaylist(); // Get the current playlist
    const currentIndex = player.getPlaylistIndex(); // Get the current index

    // Enable "Next" button if there's a next song
    return currentIndex !== undefined && currentIndex < playlist.length - 1;
  }, [player]);

  const getPrevButtonState = useCallback(() => {
    if (!player || !player.getPlaylistIndex) return false;

    const currentIndex = player.getPlaylistIndex(); // Get the current index

    // Enable "Previous" button if there's a previous song
    return currentIndex !== undefined && currentIndex > 0;
  }, [player]);

  return (
    <TrackContainer>
      <YouTubePlayer
        playlistId={playlistt}
        player={player}
        isPlaying={isPlaying}
        title={title}
        setPlayer={setPlayer}
        setIsplaying={setIsPlaying}
        setSongDetails={setSongDetails}
      />
      {songDetails ? (
        <>
          <TrackCoverContainer>
            <img
              src={
                songDetails?.video_id
                  ? `https://img.youtube.com/vi/${songDetails?.video_id}/0.jpg`
                  : PlaceholderImg
              }
              alt="playlist cover"
              width={320}
              height={180}
            />
          </TrackCoverContainer>
          {isOwner && (
            <PlayerControlsContainer>
              <ControlButton>
                <button
                  style={{ border: "none", background: "transparent" }}
                  onClick={() => {
                    player.setShuffle(!isShuffled);
                    PlayaToast.success({
                      msg: isShuffled ? "Shuffle Off!" : "Shuffle On!",
                    });
                    setIsShuffled(!isShuffled);
                  }}
                >
                  <ShuffleIcon color={isShuffled ? "#008135" : null} />
                </button>
              </ControlButton>
              <ControlButton>
                <button
                  style={{ border: "none", background: "transparent" }}
                  onClick={getPrevButtonState() ? handlePrev : null}
                  disabled={!getPrevButtonState()}
                >
                  <PreviousIcon
                    color={getPrevButtonState() ? null : "#cccccc"}
                  />
                </button>
              </ControlButton>
              <ControlButton>
                {!isPlaying ? (
                  <button
                    style={{ border: "none", background: "transparent" }}
                    onClick={handlePlay}
                  >
                    <PlayIcon />
                  </button>
                ) : (
                  <button
                    style={{ border: "none", background: "transparent" }}
                    onClick={handlePause}
                  >
                    <PauseIcon />
                  </button>
                )}
              </ControlButton>
              <ControlButton>
                <button
                  style={{ border: "none", background: "transparent" }}
                  onClick={getNextButtonState() ? handleNext : null}
                  disabled={!getNextButtonState()}
                >
                  <NextIcon color={getNextButtonState() ? null : "#cccccc"} />
                </button>
              </ControlButton>
              <ControlButton>
                <button
                  style={{ border: "none", background: "transparent" }}
                  onClick={() => setShowPlaylist(!showPlaylist)}
                >
                  <PlayerIcon />
                </button>
              </ControlButton>
            </PlayerControlsContainer>
          )}
          <TrackDescription>
            <h5>{songDetails?.title}</h5>
            <Artist>
              <h6>{songDetails?.author}</h6>
            </Artist>
          </TrackDescription>
          {showPlaylist && (
            <ViewPlayListScreen
              current_track={{
                album: {
                  images: [
                    {
                      url: songDetails?.video_id
                        ? `https://img.youtube.com/vi/${songDetails?.video_id}/0.jpg`
                        : "",
                    },
                  ],
                },
              }}
              setShowPlaylist={setShowPlaylist}
            />
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
    </TrackContainer>
  );
};

// Styled Components
const TrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const TrackCoverContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  img {
    border-radius: 14px;
    object-fit: cover;
    object-position: center;
  }
`;

const PlayerControlsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ControlButton = styled.div`
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const TrackDescription = styled.div`
  display: flex;
  flex-direction: column;
  h5 {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  h6 {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const Artist = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
