import { setCardDetails, setGlobalPlayer } from "app/api/otherSlice";
import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

interface YouTubePlayerProps {
  playlistId: string;
  player: any;
  isPlaying: boolean;
  setSongDetails: React.Dispatch<React.SetStateAction<any>>;
  setIsplaying: React.Dispatch<React.SetStateAction<boolean>>;
  setPlayer: React.Dispatch<React.SetStateAction<any>>;
  title: string;
  currentSongIndex?: number;
}

// Extend the Window interface
declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}

export const YouTubePlayer: React.FC<YouTubePlayerProps> = ({
  playlistId,
  setPlayer,
  setSongDetails,
  title,
  currentSongIndex,
}) => {
  const playerRef = useRef<HTMLIFrameElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (window.YT && window.YT.Player) {
        createPlayer();
        return;
      }

      // Check if the script is already loaded
      const existingScript = document.querySelector(
        'script[src="https://www.youtube.com/iframe_api"]'
      );
      if (!existingScript) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        if (firstScriptTag && firstScriptTag.parentNode) {
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
      }

      window.onYouTubeIframeAPIReady = createPlayer;
    };

    const onPlayerReady = (event: any, player: any) => {
      if (!playlistId) {
        console.error("No playlist ID provided.");
        return;
      }

      player.loadPlaylist({ list: playlistId }); // Load the playlist and start playback
      if (currentSongIndex) {
        // Start playback from the specified song
        player.playVideoAt(currentSongIndex);
      } else {
        player.playVideo(); // Fallback: Start from the beginning
      }

      // Unmute if needed (optional for audio-only)
      player.unMute();

      dispatch(setGlobalPlayer({ player }));
    };

    const onPlayerStateChange = (event: any, player: any) => {
      if (event.data === window.YT.PlayerState.PLAYING) {
        const song = player.getVideoData();
        if (song?.video_id) {
          setSongDetails(song);

          const roomDetails = {
            roomID: playlistId,
            roomName: title,
            playlistId: playlistId,
            roomDescription: "Room description should be here",
            songAuthor: song?.author || "Unknown",
            songTitle: song?.title || "Unknown",
            comments: 0,
            roomMembers: 0,
            image: `https://img.youtube.com/vi/${song?.video_id}/0.jpg`,
            player: null,
            IsRoomOwner: true,
            currentIndex: player?.getPlaylistIndex(),
            app: "youtube",
          };
          dispatch(setCardDetails({ cardDetails: roomDetails }));
        } else {
          console.warn("No video data available.");
        }
      }
    };
    const onError = (event: any) => {
      const errorCodes = {
        2: "Invalid parameter", // Recoverable: Temporary issue
        5: "HTML5 player falls back to Flash", // Recoverable: Might still work
        100: "Video not found", // Unrecoverable: Video does not exist
        101: "Content owner restricted playback", // Unrecoverable: Restricted content
        150: "Content owner restricted playback", // Unrecoverable: Restricted content
      };

      const errorCode: keyof typeof errorCodes = event.data;
      const errorMessage = errorCodes[errorCode] || "Unknown error";

      console.error(`Player error (${errorCode}): ${errorMessage}`);

      if ([100, 101, 150].includes(Number(errorCode))) {
        // Unrecoverable errors: Show toast and redirect after 5 seconds
        toast.error(
          "Oops! An error occurred retrieving this playlist. Please try another."
        );

        // Debounce the redirect to give the user time to see the toast
        setTimeout(() => {
          window.location.href = "/home"; // Redirect to home page
        }, 5000); // Wait 5 seconds before redirecting
      } else {
        // Recoverable errors: Log the error but do not interrupt the user
        console.warn(`Recoverable error (${errorCode}): ${errorMessage}`);
      }
    };

    const createPlayer = () => {
      const player = new window.YT.Player(playerRef.current, {
        playerVars: {
          autoplay: 1, // Enable autoplay
          controls: 0, // Hide controls (audio-only)
          mute: 1, // Mute initially for autoplay compliance
          showinfo: 0, // Hide video info
          iv_load_policy: 3, // Disable annotations
          modestbranding: 1, // Remove YouTube logo
        },
        events: {
          onReady: (event: any) => onPlayerReady(event, player),
          onStateChange: (event: any) => onPlayerStateChange(event, player),
          onError: onError,
        },
      });
      setPlayer(player);
    };

    loadYouTubeAPI();
  }, [playlistId]);

  const domain =
    window.location.hostname === "localhost"
      ? `http://localhost:3000`
      : `https://${window.location.hostname}`;
  return (
    <iframe
      title="Playa"
      id="youtube_player"
      width="100%"
      allow="autoplay"
      src={`https://www.youtube.com/embed?listType=playlist&list=${playlistId}&enablejsapi=1&origin=${encodeURIComponent(domain)}&autoplay=1&mute=1`}
      style={{ display: "none" }} // Hide the video portion (audio-only)
      ref={playerRef}
    />
  );
};
